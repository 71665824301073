import React, { useCallback, useState } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useRecoilState } from "recoil";
import { logCurrentPageState, logDataState, logTotalPageState } from "../../../atoms/log";
import axios from "../../../api/axios";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Errors from "../../../utils/Errors";

const LogTableHead = () => {
  const [, setlogData] = useRecoilState(logDataState);
  const [, setlogTotalPage] = useRecoilState(logTotalPageState);
  const [logCurrentPage, setlogCurrentPage] = useRecoilState(logCurrentPageState);

  const [activeSort, setActiveSort] = useState([
    { column: "member_id", direction: null },
    { column: "created_at", direction: null },
  ]);

  const handlePageChange = useCallback(
    async (orderBy) => {
      const updatedSort = activeSort.map((sort) => {
        if (sort.column === orderBy) {
          return {
            ...sort,
            direction: sort.direction === "ASC" ? "DESC" : "ASC",
          };
        }
        return {
          ...sort,
          direction: null, // Diğer sütunların yönünü varsayılan duruma sıfırlayın
        };
      });

      setActiveSort(updatedSort);

      const currentDirection = updatedSort.find((sort) => sort.column === orderBy).direction;

      await axios
        .get(`log/check-price-logs`, {
          params: {
            page: logCurrentPage,
            limit: 500,
            order_by: orderBy,
            order_direction: currentDirection,
          },
        })
        .then((resp) => {
          setlogData(resp.data.data);
          setlogCurrentPage(resp.data.currentPage);
          setlogTotalPage(resp.data.totalPages);
        })
        .catch((error) => Errors(error));
    },
    [setlogData, setlogCurrentPage, setlogTotalPage, logCurrentPage, activeSort]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("member_id")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Müşteri ID{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "member_id")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Müşteri Adı</TableCell>
        <TableCell
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => handlePageChange("created_at")}
        >
          <div style={{ fontSize: "inherit", display: "flex", gap: "5px" }}>
            Tarih{" "}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                transform:
                  activeSort.find((sort) => sort.column === "created_at")?.direction === "ASC"
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </TableCell>
        <TableCell sx={{ fontWeight: 700 }}>İp Adresi</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Doviz Cinsi</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Durum</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default LogTableHead;
