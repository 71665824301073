import { useState, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useRecoilState, useRecoilValue } from "recoil";
import { ffdIyziTeamDimemsionState } from "../../../../atoms/operation";

export default function OperationDimension({ items, i, disable, isBeevision }) {
  const [dimensions, setDimensions] = useRecoilState(ffdIyziTeamDimemsionState);

  const [totalCw, setTotalCw] = useState();

  const roundWeight = (weight) => {
    if (weight <= 0.01) {
      return 0.01;
    } else if (weight < 10) {
      const integerPart = Math.floor(weight);
      const decimalPart = weight - integerPart;
      if (decimalPart > 0 && decimalPart <= 0.5) {
        return integerPart + 0.5;
      } else {
        return Math.ceil(weight * 2) / 2;
      }
    } else {
      return Math.ceil(weight);
    }
  };

  const handleInputChange = useCallback((e, i) => {
    const { name, value } = e.target;

    setDimensions((prevDimensions) => {
      const updatedDimensions = [...prevDimensions];
      let fvalue = value.replace(/,/g, ".");

      if (name === "qty") {
        updatedDimensions[i] = {
          ...updatedDimensions[i],
          qty: fvalue.replace(/[^0-9]/g, ""),
        };
      } else {
        const value = fvalue.replace(/[^0-9.]|(?<=\..*)\.|^0{2,}(?=[\.])/g, "");
        const regex = /^\d+(\.\d{0,2})?$/;

        if (value.startsWith(".") || (!regex.test(value) && value !== "")) {
          return prevDimensions;
        } else {
          if (name === "gross") {
            let newValue = value.replace(/^0{2,}/, "0");
            if (Number(fvalue) > 300) {
              newValue = "300";
            }
            updatedDimensions[i] = {
              ...updatedDimensions[i],
              gross: newValue,
            };
          } else {
            updatedDimensions[i] = {
              ...updatedDimensions[i],
              [name]: value,
            };
          }
        }
      }

      const calculatedCw = updatedDimensions?.map((dimension) =>
        ((Number(dimension.width) *
          Number(dimension.length) *
          Number(dimension.height)) /
          5000) *
          Number(dimension.qty) >
        Number(dimension.gross) * Number(dimension.qty)
          ? ((Number(dimension.width) *
              Number(dimension.length) *
              Number(dimension.height)) /
              5000) *
            Number(dimension.qty)
          : Number(dimension.gross) * Number(dimension.qty)
      );

      setTotalCw(
        roundWeight(calculatedCw?.reduce((acc, item) => acc + item, 0))
      );
      return updatedDimensions;
    });
  }, []);

  useEffect(() => {
    if (i > 0) {
      // Request sonucunda gelen veri "responseData" olarak adlandırılmıştır.
      const filteredItems = items?.filter((item) => item.status === "2");
      if (filteredItems.length > 0) {
        const transformedData = filteredItems?.map((item, index) => ({
          width: item.width || "",
          length: item.length || "",
          height: item.height || "",
          gross: item.gross || "",
          qty: item.qty || "",
          id: item.id !== 0 ? item.id : 0, // Bu kısmı, unique bir id almak için item.id olarak da ayarlayabilirsiniz.
        }));

        // Dönüştürülmüş veriyi "dimensions" state'ine ayarlama
        setDimensions(transformedData);
        const calculatedCw = transformedData?.map((dimension) =>
          ((Number(dimension.width) *
            Number(dimension.length) *
            Number(dimension.height)) /
            5000) *
            Number(dimension.qty) >
          Number(dimension.gross) * Number(dimension.qty)
            ? ((Number(dimension.width) *
                Number(dimension.length) *
                Number(dimension.height)) /
                5000) *
              Number(dimension.qty)
            : Number(dimension.gross) * Number(dimension.qty)
        );

        setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
      } else {
        const transformedData = items?.map((item, index) => ({
          //width: item.width,
          width: "",
          // length: item.length,
          length: "",
          // height: item.height,
          height: "",
          // gross: item.gross,
          gross: "",
          // qty: item.qty,
          qty: "",
          id: 0, // Bu kısmı, unique bir id almak için item.id olarak da ayarlayabilirsiniz.
        }));

        // Dönüştürülmüş veriyi "dimensions" state'ine ayarlama
        setDimensions(transformedData);
        const calculatedCw = transformedData?.map((dimension) =>
          ((Number(dimension.width) *
            Number(dimension.length) *
            Number(dimension.height)) /
            5000) *
            Number(dimension.qty) >
          Number(dimension.gross) * Number(dimension.qty)
            ? ((Number(dimension.width) *
                Number(dimension.length) *
                Number(dimension.height)) /
                5000) *
              Number(dimension.qty)
            : Number(dimension.gross) * Number(dimension.qty)
        );

        setTotalCw(calculatedCw?.reduce((acc, item) => acc + item, 0));
      }

      // Gelen veriyi "dimensions" state formatına dönüştüren bir fonksiyon
    }
  }, [i, items, setDimensions]);
  console.log("dimensions", dimensions);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--gap)",
        opacity: disable ? "0.5" : "1",
      }}
    >
      {i > 0 &&
        dimensions.map((dim, index) => (
          <form
            onSubmit={(e) => e.preventDefault()} // Formun submitini engelliyoruz
            className="operation-cargo-dom-resp-weight-iyziteam-dimension"
            key={index}
            style={{
              display: isBeevision === true ? "flex" : "",
              gap: isBeevision === true ? "20px" : "",
              paddingTop: isBeevision === true ? "20px" : "",
            }}
          >
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Genişlik</div>
              <TextField
                size="medium"
                name="width"
                value={dim.width}
                disabled={disable}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Uzunluk</div>
              <TextField
                size="medium"
                name="length"
                required
                value={dim.length}
                disabled={disable}
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Yükseklik</div>
              <TextField
                size="medium"
                name="height"
                value={dim.height}
                disabled={disable}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Kap</div>
              <TextField
                size="medium"
                value={dim.qty}
                required
                disabled={disable}
                onChange={(e) => handleInputChange(e, index)}
                name="qty"
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Kilogram</div>
              <TextField
                size="medium"
                name="gross"
                disabled={disable}
                value={dim.gross}
                required
                onChange={(e) => handleInputChange(e, index)}
                sx={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                  "& fieldset": { border: "none" },
                }}
              />
            </div>

            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">
                <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
              </div>
              <div
                style={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                {Number(dim.gross * dim.qty).toFixed(2)} KG
              </div>
            </div>
            <div className="operation-cargo-dom-resp-weight-iyziteam-item">
              <div className="little-title">Hacim / Desi</div>
              <div
                style={{
                  backgroundColor: "#e9ecef",
                  width: "70px",
                  height: "20px",
                  fontSize: "10px",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                {Number(
                  ((dim.width * dim.length * dim.height) / 5000) * dim.qty
                ).toFixed(2)}
                KG
              </div>
            </div>
          </form>
        ))}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#6a707e",
          gap: "5px",
        }}
        className="operation-cargo-dom-resp-wight-shipment-bottom-side"
      >
        <div style={{ fontSize: "7px" }} className="little-title">
          Toplam Ücret Ağırlığı
        </div>
        <div
          style={{
            backgroundColor: "#e9ecef",
            width: "124px",
            height: "20px",
            fontSize: "10px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="operation-cargo-dom-resp-wight-shipment-item-bottom"
        >
          {totalCw} kg
        </div>
      </div>
    </div>
  );
}
