import { Box } from "@mui/material";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import axios from "../../api/axios";
import MyShipmentsList from "../components/myShipments/MyShipmentsList";
import MyShipmentSearch from "../components/myShipments/MyShipmentsSearch";
import EditShipmentAddress from "../components/myShipments/modals/EditShipmentAddress";
import EditShipmentSenderAddress from "../components/myShipments/modals/EditShipmentSenderAddress";
import { DynamicTitle } from "../../utils/DynamicTitle";
import NavBar from "../components/sidebar/NavBar";
import "../../style/myShipments/MyShipments.css";
import AddNote from "../components/myShipments/modals/AddNote";
import MyShipmentUpload from "../components/myShipments/modals/MyShipmentUpload";
import ShowFinancialDetails from "../components/myShipments/modals/ShowFinancialDetails";
import ConnectedPayments from "../components/myShipments/modals/ConnectedPayments";
import ShipmentDetail from "../components/myShipments/modals/ShipmentDetail";
import MyShipmentProformaUpdate from "../components/myShipments/modals/MyShipmentProformaUpdate";
import PastAddress from "../components/myShipments/modals/PastAddress";
import CheckPointModal from "../components/myShipments/modals/CheckPointModal";
import CaseTransactionsModal from "../components/myShipments/modals/CaseTransactionsModal";
import UpdateHAWBModal from "../components/myShipments/modals/UpdateHAWBModal";
import DimensionsModal from "../components/myShipments/modals/DimensionsModal";
import FilesModal from "../components/myShipments/modals/FilesModal";
import SupplierChangeModal from "../components/myShipments/modals/SupplierChangeModal";
import StatusChangeModal from "../components/myShipments/modals/StatusChangeModal";
import SupplierConsignmentDownload from "../components/myShipments/modals/SupplierConsignmentDownload";
import ShipmentDimensionDetail from "../components/myShipments/modals/ShipmentDimensionDetail";
import WrapperStyle from "../../utils/WrapperStyle";
import HistoryOfChange from "../components/myShipments/modals/HistoryOfChange";
import ExtraPriceAdd from "../components/myShipments/modals/ExtraPriceAdd";
import NotificationBar from "../components/sidebar/NotificationBar";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../atoms/viewPadding";
import DomesticAwbView from "../components/myShipments/modals/DomesticAwbView";
import WeightCheckModal from "../components/myShipments/modals/WeightCheckModal";
import ShipmentContractDetails from "../components/myShipments/modals/ShipmentContractDetails";

const MyShipments = () => {
  DynamicTitle("Gönderilerim - Admin");

  const drawerOpen = useRecoilValue(drawerOpenState);

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  return (
    <>
      <NotificationBar />
      <section className="myShipments" style={WrapperStyle()}>
        <NavBar />
        <ToastContainer />
        <Box
          className="myShipments-wrapper"
          sx={{
            paddingLeft: drawerOpen ? 0 : "64px",
            transition: "all 150ms linear",
            paddingTop: "20px",
          }}
        >
          <MyShipmentSearch />
          <MyShipmentsList />
        </Box>
        <>
          <EditShipmentAddress />
          <EditShipmentSenderAddress />
          <AddNote />
          <MyShipmentUpload />
          <ShowFinancialDetails />
          <ConnectedPayments />
          <ShipmentDetail />
          <MyShipmentProformaUpdate />
          <PastAddress />
          <CaseTransactionsModal />
          <CheckPointModal />
          <UpdateHAWBModal />
          <DimensionsModal />
          <FilesModal />
          <SupplierChangeModal />
          <StatusChangeModal />
          <SupplierConsignmentDownload />
          <ShipmentDimensionDetail />
          <ShipmentContractDetails />
          <HistoryOfChange />
          <ExtraPriceAdd />
          <DomesticAwbView />
          <WeightCheckModal />
        </>
      </section>
    </>
  );
};

export default MyShipments;
