import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  memberBasicSearchState,
  memberBasicSearchErrorState,
  memberDetailedStatusState,
  memberListLoadingState,
  memberListState,
  memberListCurrentPageState,
  memberTotalPageState,
  memberCountState,
} from "../../../atoms/members";
import SearchIcon from "@mui/icons-material/Search";
import MemberDetailedSearch from "./MemberDetailedSearch";
import "../../../style/members/Members.css";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";
import DownloadBase64Excel from "../../../utils/DownloadBase64Excel";
import {toast} from "react-toastify";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: "auto",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px 0",
        },
      },
    },
  },
});
const MemberSearch = () => {
  const basicSearchError = useRecoilValue(memberBasicSearchErrorState);

  const [basicSearch, setBasicSearch] = useRecoilState(memberBasicSearchState);
  const [memberCount, setMemberCount] = useRecoilState(memberCountState);

  const [memberList, setMemberList] = useRecoilState(memberListState);
  const setMemberListLoading = useSetRecoilState(memberListLoadingState);
  const setMemberDetailedStatus = useSetRecoilState(memberDetailedStatusState);
  const setMemberListCurrentPage = useSetRecoilState(
    memberListCurrentPageState
  );
  const setMemberTotalPage = useSetRecoilState(memberTotalPageState);

  const [individualSelected, setIndividualSelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [allSelected, setAllSelected] = useState("all");

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGetMemberCounts = useCallback(async () => {
    await axios
      .get(`member`)
      .then((response) => {
        setMemberCount(response.data);
      })
      .catch((error) => Errors(error));
  }, [setMemberCount]);

  const handleBasicSearch = useCallback(async () => {
    setMemberListLoading(true);
    setMemberDetailedStatus(0);

    const params = {
      search: basicSearch,
    };

    await axios
      .get(`member`, { params: params })
      .then((response) => {
        setMemberList(response.data.data);
      })
      .catch((error) => Errors(error))
      .finally(() => setMemberListLoading(false));
  }, [
    setMemberList,
    basicSearch,
    setMemberDetailedStatus,
    setMemberListLoading,
  ]);

  const handleDetailedSearch = useCallback(
    async (type) => {
      setMemberListLoading(true);
      setMemberDetailedStatus(0);

      const params = {
        detailed: 1,
        member_type: type,
      };

      await axios
        .get(`member`, { params: params })
        .then((response) => {
          setMemberList(response.data.data);
          setMemberTotalPage(response.data.totalPages);
          setMemberListCurrentPage(response.data.currentPage);
        })
        .catch((error) => Errors(error))
        .finally(() => setMemberListLoading(false));
    },
    [
      setMemberList,
      setMemberListLoading,
      setMemberDetailedStatus,
      setMemberTotalPage,
      setMemberListCurrentPage,
    ]
  );

  const handleClearBasicSearch = () => {
    setBasicSearch("");
  };

  const handleIndividualSearch = () => {
    handleDetailedSearch("Individual");
    setIndividualSelected("individual");
    setCompanySelected("");
    setAllSelected("");
  };
  const handleCompanySearch = () => {
    handleDetailedSearch("Company");
    setIndividualSelected("");
    setCompanySelected("company");
    setAllSelected("");
  };
  const handleAllSearch = () => {
    handleDetailedSearch("");
    setIndividualSelected("");
    setCompanySelected("");
    setAllSelected("all");
  };

  const handleLastCreatedParcelReport = useCallback(async () => {
    await axios
        .post(`member/last-created-parcel-report`)
        .then((response) => {
          toast.success(response.data.message);
          console.log(response);
          DownloadBase64Excel(
              response.data.base64,
              "Gonderiler",
              "parcel",
              "",
              ""
          );
        })
        .catch((error) => Errors(error));
  }, [setMemberCount]);

  useEffect(() => {
    handleGetMemberCounts();
  }, []);

  useEffect(() => {
    if(basicSearch != "") {
      handleBasicSearch();
    }
  }, [basicSearch]);

  console.log(basicSearch.length)

  return (
    <ThemeProvider theme={theme}>
      <Box className="member-search">
        <Card className="search-card">
          <CardContent className="search-card-content">
            <Box sx={{ display: "flex", gap: "var(--gap)", width: "100%" }}>
              <TextField
                fullWidth
                className="search-card-content-search"
                label="Üye Arama"
                placeholder="Üye İsmi,Kimlik No,Email,Kullanıcı İsmi,Kullanıcı Kimlik No"
                value={basicSearch}
                error={basicSearchError}
                autoComplete="off"
                helperText={
                  basicSearchError
                    ? "Arama kriterlerinizi değiştirip yeniden deneyiniz."
                    : ""
                }
                onChange={(e) => setBasicSearch(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? handleBasicSearch()
                    : e.key === "Escape"
                    ? handleClearBasicSearch()
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
            <Box sx={{ display: "flex", gap: "var(--gap)" }}>
              <Button
                className="iyzi-button"
                sx={{ minWidth: "150px" }}
                onClick={handleExpandClick}
              >
                {expanded ? "Detaylı Aramayı Kapat" : "Detaylı Aramayı Aç"}
              </Button>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <MemberDetailedSearch />
            </CardContent>
          </Collapse>
        </Card>
        <div className="member-type">
          <div
            className={`member-type-item ${
              individualSelected === "individual" ? "selected-member-type" : ""
            }`}
            onClick={handleIndividualSearch}
          >
            <div className="member-type-item-child individual-type" />
            Bireysel {memberCount?.individual_membership_count}
          </div>

          <div
            className={`member-type-item ${
              companySelected === "company" ? "selected-member-type" : ""
            }`}
            onClick={handleCompanySearch}
          >
            <div className="member-type-item-child company-type" />
            Kurumsal {memberCount?.company_membership_count}
          </div>

          <div
            className={`member-type-item ${
              allSelected === "all" ? "selected-member-type" : ""
            }`}
            onClick={handleAllSearch}
          >
            <div className="member-type-item-child all-type" />
            Hepsi {memberCount?.totalItems}
          </div>

          <Button variant="contained" onClick={handleLastCreatedParcelReport}>
            Son Gönderi Oluşturma Bilgisini İndir
          </Button>

        </div>
      </Box>
    </ThemeProvider>
  );
};

export default MemberSearch;
