import {
  Box,
  Button,
  createTheme,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Switch,
  ThemeProvider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import {
  awbState,
  basicSearchState,
  detailedStatusState,
  exportSelectedDesktopState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentsListArrayLengthState,
  shipmentStatusState,
  timeState,
  timeStartState,
  timeEndState,
  totalPagesState,
  totalItemsState,
  selectedMyShipmentListItemState,
  listStyleState, selectedSearchDateTypeState, selectedTrackingStatusState, erasedShipmentState
} from "../../../atoms/myShipments";
import MyShipmentsListItem from "./MyShipmentsListItem";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import { useLocation } from "react-router-dom";
import miniLogo from "../../../img/logos/logo_white_mini_loading.svg";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Errors from "../../../utils/Errors";
import DownloadBase64Pdf from "../../../utils/DownloadBase64Pdf";
import DownloadBase64Excel from "../../../utils/DownloadBase64Excel";
import { searchAdminBakiyeIdState } from "../../../atoms/finance";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
        },
      },
    },
  },
});

const MyShipmentsList = () => {
  const shipmentOrderDirectionPage = useRecoilValue(
    shipmentListOrderDirectionState
  );

  const [myShipmentListLoading, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [myShipmentList, setMyShipmentList] =
    useRecoilState(myShipmentListState);
  const [myShipmentsListLimit, setMyShipmentsListLimit] = useRecoilState(
    myShipmentsListLimitState
  );
  const [shipmentListCurrentPage, setShipmentListCurrentPage] = useRecoilState(
    shipmentListCurrentPageState
  );
  const [, setShipmentsListArrayLength] = useRecoilState(
    shipmentsListArrayLengthState
  );
  const [exportSelectedDesktop, setExportSelectedDesktop] = useRecoilState(
    exportSelectedDesktopState
  );


  const [exportFromIds, setExportFromIds] = useState(0);
  const [exportedIds, setExportIds] = useState([]);


  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] =
    useRecoilState(selectedMyShipmentListItemState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [totalItems, setTotalItems] = useRecoilState(totalItemsState);
  const [listStyle, setListStyle] = useRecoilState(listStyleState);

  const time = useRecoilValue(timeState);
  const timeStart = useRecoilValue(timeStartState);
  const timeEnd = useRecoilValue(timeEndState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedSearchDateType = useRecoilValue(selectedSearchDateTypeState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const selectedTrackingStatus = useRecoilValue(selectedTrackingStatusState);
  const selectedDeletedStatus = useRecoilValue(erasedShipmentState);
  const selectedMemberID = useRecoilValue(searchAdminBakiyeIdState);
  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const shipmentStatus = useRecoilValue(shipmentStatusState);
  const detailedStatus = useRecoilValue(detailedStatusState);
  const basicSearch = useRecoilValue(basicSearchState);


  const getShipment = useCallback(async () => {
    setMyShipmentListLoading(true);

    await axios
      .get(
        detailedStatus === "1"
          ? `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${shipmentListCurrentPage}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${selectedSupplier !== ""
            ? Number(selectedSupplier)
            : selectedSupplier
          }&status=${shipmentStatus}&created_at=${time}&from_date${timeStart}&to_date=${timeEnd}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&member_id${selectedMemberID}&detailed=1`
          : `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${shipmentListCurrentPage}`
      )
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
        setShipmentsListArrayLength(response.data.data.length);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  }, [
    setMyShipmentList,
    setMyShipmentListLoading,
    setShipmentsListArrayLength,
    setTotalPages,
    setTotalItems,
    myShipmentsListLimit,
    shipmentListCurrentPage,
    shipmentOrderDirectionPage,
    time,
    timeStart,
    timeEnd,
    senderCountryCode,
    receiverCountryCode,
    selectedSupplier,
    selectedPriceStatus,
    selectedExportStatus,
    selectedDomesticStatus,
    selectedCaseStatus,
    awb,
    refId,
    sender,
    receiver,
    shipmentStatus,
    detailedStatus,
  ]);

  const handlePageChange = async (e, pageValue) => {
    setShipmentListCurrentPage(pageValue);
    setMyShipmentListLoading(true);
    await axios
      .get(
        detailedStatus === 1
          ? `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${pageValue}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier
          }&status=${shipmentStatus}&deleted=${selectedDeletedStatus}&created_at=${time}` +
          `${selectedSearchDateType === "created_at"
            ? `&arrived_from_date=${timeStart}&arrived_to_date=${timeEnd}`
            : `&from_date=${timeStart}&to_date=${timeEnd}`
          }` +
          `&date_search_type=${selectedSearchDateType}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&is_domestic=${selectedDomesticStatus}&is_case=${selectedCaseStatus}&member_id=${selectedMemberID}&paid=${selectedPriceStatus}&detailed=${Number(
            detailedStatus
          )}`
          : `parcel?order_direction=${shipmentOrderDirectionPage}&limit=${myShipmentsListLimit}&page=${pageValue}&search=${basicSearch}`

      )
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalItems);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  };

  // Domestic

  // Format the domesticAWBDate to "Y-m-d H:i:s"
  // const formattedDomesticAWBDate = moment(domesticAWBDate).format("YYYY-MM-DD HH:mm:ss");

  const [domesticModalOpen, setDomesticModalOpen] = useState(false);
  const [domesticSupplier, setDomesticSupplier] = useState("");
  const [domesticAWB, setDomesticAWB] = useState("");
  const [domesticAWBDate, setDomesticAWBDate] = useState("");

  const handleDomesticModalOpen = () => {
    setDomesticModalOpen(true);
  };

  // Function to close the DomesticInfoModal
  const handleDomesticModalClose = () => {
    setDomesticModalOpen(false);
  };

  // Function to handle the domestic information update
  const handleDomesticInfoUpdate = async () => {
    if (!domesticSupplier && !domesticAWB && !domesticAWBDate) {
      handleDomesticModalClose();
      return;
    }
    setMyShipmentListLoading(true);

    try {
      const response = await axios.post(`parcel/parcel-domestic/`, {
        domestic_awb: domesticAWB,
        domestic_supplier: domesticSupplier,
        domestic_awb_date: domesticAWBDate,
      });
      if (response.status === 200) {
        // Handle the response data if necessary
        toast.success(response.data.message, { autoClose: 500 });
      } else {
        toast.error(response.data.message, { autoClose: 500 });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        autoClose: 500,
      });
    }

    setMyShipmentListLoading(false);

    // Clear the form after handling the update
    setDomesticSupplier("");
    setDomesticAWB("");
    setDomesticAWBDate("");

    // Close the modal
    handleDomesticModalClose();
  };

  const handleExport = useCallback(
    (value) => {
      if (selectedMyShipmentListItem.length > 0) {
        axios
          .post(`parcel/export-selected-parcel`, {
            ids: selectedMyShipmentListItem,
            exportFromIds,  // tümünü seç yapıldımı
            exportedIds     // tümünü seç isteginden gelen parcel Idleri.
          })
          .then((res) => {
            console.log(res)
            if (res.data.status === 200) {
              DownloadBase64Excel(
                res.data.base64,
                "Gonderiler",
                "parcel",
                "",
                ""
              );
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((error) => Errors(error));
        setExportSelectedDesktop(value);
      }
      else {
        return toast.error("Gönderi Seçilmedi");
      }
    },
    [setExportSelectedDesktop, selectedMyShipmentListItem, exportedIds, exportFromIds]
  );

  // awb parametresi url'de bulunuyorsa silmek için.
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbParam = searchParams.get("memberId");

  useEffect(() => {
    if (!basicSearch) {
      getShipment();
    }
  }, []);

  useEffect(() => {
    console.log("Exportable IDs  Set Edildi.");
    setExportIds(exportedIds);
  }, [exportedIds]);

  useEffect(() => {
    console.log("Exportable ID Status Set Edildi.");
    setExportFromIds(exportFromIds);
    console.log(exportFromIds);
  }, [exportFromIds]);



  const handleAllCheck = useCallback(() => {

    if (selectedMyShipmentListItem.length === 0) {
      if (totalPages === 1) {
        setSelectedMyShipmentListItem(myShipmentList);
        setExportFromIds(0);
        setExportIds([]);
      }
      else {
        axios
          .post(`parcel/get-exportable-ids`, {
            filters: {
              tracking_awb: awb,
              parcel_ref_no: refId,
              supplier_id: selectedSupplier,
              date_search_type: selectedSearchDateType,
              date: time,
              from_date: timeStart,
              to_date: timeEnd,
              sender_name: sender,
              receiver_name: receiver,
              sender_country: senderCountryCode,
              receiver_country: receiverCountryCode,
              paid: selectedPriceStatus,
              has_tracking_awb: selectedTrackingStatus,
              deleted: selectedDeletedStatus,
              parcel_type: selectedExportStatus,
              is_domestic: selectedDomesticStatus,
              is_case: selectedCaseStatus,
              member_id: selectedMemberID
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              setExportIds(res.data.ids);
              setExportFromIds(1);
              setSelectedMyShipmentListItem(res.data.ids);
            } else {
              toast.error(res.data.message);
              setExportFromIds(0);
              setExportIds([]);
              setSelectedMyShipmentListItem([]);
            }
          })
          .catch((error) => Errors(error));
      }
    } else {
      if (selectedMyShipmentListItem.length !== myShipmentList.length) {
        setSelectedMyShipmentListItem(myShipmentList);
        setExportFromIds(0);
        setExportIds([]);
      } else {
        setSelectedMyShipmentListItem([]);
        setExportFromIds(0);
        setExportIds([]);
      }
    }
  }, [
    totalPages,
    selectedMyShipmentListItem,
    myShipmentList,
    setSelectedMyShipmentListItem,
    awb,
    refId,
    selectedSupplier,
    selectedSearchDateType,
    time,
    timeStart,
    timeEnd,
    sender,
    receiver,
    senderCountryCode,
    receiverCountryCode,
    selectedPriceStatus,
    selectedTrackingStatus,
    selectedDeletedStatus,
    selectedExportStatus,
    selectedCaseStatus,
    selectedDomesticStatus,
    selectedMemberID
  ]);

  return (
    <ThemeProvider theme={theme}>
      {myShipmentListLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="" />
        </div>
      ) : myShipmentList.length === 0 ? (
        <Box className="myShipments-list-none">Gönderi Oluşturmadınız.</Box>
      ) : (
        <Box className="myShipments-list">
          <Box className="myShipments-list-selectors">
            <Box className="list-selectors-position start">
              <FormControl
                className="selector-limit"
                size="small"
                sx={{ minWidth: 100 }}
              >
                <InputLabel sx={{ fontSize: "13px" }} id="limit-label">
                  Gönderi Sayısı
                </InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit"
                  label="Gönderi Sayısı"
                  sx={{
                    color: "var(--parcelText)",
                    fontSize: "13px",
                    display: "flex",
                  }}
                  value={myShipmentsListLimit}
                  onChange={(e) => setMyShipmentsListLimit(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={totalPages}
                page={shipmentListCurrentPage}
                onChange={handlePageChange}
              />
              <Typography>

                Gönderi Sayısı:{" "}
                {totalItems}
              </Typography>
            </Box>
            <Box className="list-selectors-position-wrapper">
              <Box className="list-selectors-position middle">
                <Button
                  className="selectors-button"
                  onClick={handleDomesticModalOpen}
                >
                  <LocalShippingOutlinedIcon />
                  Seçilenlerin Domestik Bilgisini Gir
                </Button>
                <Button className="selectors-button">
                  <QrCode2OutlinedIcon />
                  Seçilenlerin Barkodunu Oluştur
                </Button>
              </Box>
              <Box className="list-selectors-position end">
                <FormControl
                  className="selector-export"
                  size="small"
                  sx={{ minWidth: 180 }}
                >
                  <InputLabel
                    sx={{ fontSize: "13px", color: "var(--lighterBg)" }}
                    id="export-label"
                  >
                    Seçilenleri Dışarı Aktar
                  </InputLabel>
                  <Select
                    labelId="export-label"
                    id="export"
                    label="Seçilenleri Dışarı Aktar"
                    value={exportSelectedDesktop}
                    onChange={(e) => setExportSelectedDesktop(e.target.value)}
                    sx={{
                      backgroundColor: "var(--info)",
                      color: "var(--lighterBg)",
                      fontSize: "12px",
                      display: "flex",
                      minHeight: "40px",
                    }}
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem onClick={() => handleExport(0)} value={"0"}>Excel</MenuItem>
                    <MenuItem value={"1"}>PDF</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup
                  sx={{
                    backgroundColor: "var(--info)",
                    padding: "0 10px",
                    color: "var(--lighterBg)",
                    borderRadius: "var(--buttonBorderRadius)",
                    width: "180px",
                  }}
                >
                  <FormControlLabel
                    className="selector-switch-all"
                    control={
                      <Switch
                        checked={
                          selectedMyShipmentListItem.length ===
                          myShipmentList.length
                        }
                        onChange={handleAllCheck}
                      />
                    }
                    label="Tümünü Seç"
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
          <MyShipmentsListItem />
        </Box>
      )}
      <Modal
        open={domesticModalOpen}
        onClose={handleDomesticModalClose}
        aria-labelledby="domestic-info-modal-title"
        aria-describedby="domestic-info-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: 24,
            p: 4,
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.3rem",
            }}
          >
            Domestik Bilgi Ekle
          </Typography>
          <FormControl component="fieldset" fullWidth sx={{ my: 2 }}>
            <TextField
              label="Domestik Tedarikçi"
              value={domesticSupplier}
              onChange={(e) => setDomesticSupplier(e.target.value)}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth sx={{ my: 2 }}>
            <TextField
              label="Domestik AWB"
              value={domesticAWB}
              onChange={(e) => setDomesticAWB(e.target.value)}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth sx={{ my: 2 }}>
            <TextField
              label="Domestik AWB Tarih"
              value={domesticAWBDate}
              onChange={(e) => setDomesticAWBDate(e.target.value)}
            />
          </FormControl>
          <Button
            variant="contained"
            className="iyzi-button-blue"
            sx={{ mr: 3 }}
            onClick={handleDomesticInfoUpdate}
          >
            Kaydet
          </Button>
          <Button
            variant="contained"
            onClick={handleDomesticModalClose}
            className="iyzi-button-blue"
          >
            İptal
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MyShipmentsList;
