import {
  Box,
  createTheme,
  ThemeProvider,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  expandFilesState,
  ticketState,
  openSupportDetailState,
  openSupportListState,
  ticketMessagesState,
} from "../../../atoms/support";
import SupportDetailFinance from "../../../components/support/supportDetail/SupportDetailFinance";
import SupportDetailNotes from "../../../components/support/supportDetail/SupportDetailNotes";
import SupportDetailStepper from "../../../components/support/supportDetail/SupportDetailStepper";
import SupportDetailStepperCountries from "../../../components/support/supportDetail/SupportDetailStepperCountries";
import SupportDetailStepperInfo from "../../../components/support/supportDetail/SupportDetailStepperInfo";
import Currency from "../../../utils/CurrencySymbol";
import SupportDetailChatAdmin from "./supportDetail/SupportDetailChatAdmin";
import SupportDetailFilesAdmin from "./supportDetail/SupportDetailFilesAdmin";
import SupportDetailIyziNoteAdmin from "./supportDetail/SupportDetailIyziNoteAdmin";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 2px 10px rgb(0 0 0 / 10%)",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: "#6a707e",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "9999px !important",
        },
      },
    },
  },
});

const SupportDetailAdmin = () => {
  const [ticket, setTicket] = useRecoilState(ticketState);
  const expandFiles = useRecoilValue(expandFilesState);
  const [, setOpenSupportDetail] = useRecoilState(openSupportDetailState);
  const [, setOpenSupportList] = useRecoilState(openSupportListState);
  const [ticketMessages, setTicketMessages] =
    useRecoilState(ticketMessagesState);
  const [rows, setRows] = useState([]);
  const cardRef = useRef(null);
  console.log(ticket)
  const createData = (
    date,
    hour,
    type,
    price,
    exchangeRate,
    fullName,
    paid
  ) => {
    return { date, hour, type, price, exchangeRate, fullName, paid };
  };

  useEffect(() => {
    if (ticket.get_parcel !== null) {
      setRows(
        ticket.get_parcel?.get_parcel_price_informations[0]?.get_details?.map(
          (detail) =>
            createData(
              `${detail.created_at.slice(0, 10)}`,
              `${detail.created_at.slice(11, 19)}`,
              `${detail.type}`,
              `${detail.selling_price} ${Currency(
                ticket.get_parcel?.get_parcel_price_informations[0]
              )} / ${(
                Number(detail.selling_price) * Number(detail.exchange_rate)
              ).toFixed(2)} ₺`,
              `${detail.exchange_rate}`,
              `${detail.get_created_by.name} ${detail.get_created_by.surname}`,
              `${detail.paid}`
            )
        )
      );
    }
  }, [ticket]);

  useEffect(() => {
    if (expandFiles) {
      if (cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [expandFiles]);

  const trackingCargo = ticket?.get_parcel?.get_parcel_supplier?.supplier_name;

  const cargoSites = [
    {
      name: "FEDEX",
      keyword: "FX",
      link: "https://www.fedex.com/fedextrack/?trknbr=",
    },
    {
      name: "UPS",
      keyword: "1Z",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "GLS",
      keyword: "500",
      link: "https://www.gls-pakete.de/en/parcel-tracking?trackingNumber=",
    },
    {
      name: "UPS ECO",
      keyword: "1Z1",
      link: "https://www.ups.com/track?loc=tr_TR&tracknum=",
    },
    {
      name: "DPD",
      keyword: "0150",
      link: "https://tracking.dpd.de/status/en_NL/parcel/",
    },
    {
      name: "ROYAL MAİL",
      keyword: "",
      link: "https://www.royalmail.com/track-your-item#/",
    },
    {
      name: "DHL",
      keyword: "",
      link: "https://www.dhl.com/tr-tr/home/tracking.html?tracking-id=",
    },
    {
      name: "USPS",
      keyword: "9400",
      link: "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=",
    },
    {
      name: "TNT",
      keyword: "9740",
      link: "https://www.tnt.com/express/tr_tr/site/shipping-tools/tracking.html?searchType=con&cons=",
    },
  ];

  const getTrackingLinkByCargo = (cargoName, trackingNumber) => {
    const selectedCargo = cargoSites.find((cargo) => cargo.name === cargoName);
    if (selectedCargo) {
      const trackingLink = selectedCargo.link + trackingNumber;
      window.open(trackingLink, "_blank");
    } else {
      alert("Belirtilen kargo firması için uygun bir takip linki bulunamadı.");
    }
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleCloseSupportTicket = useCallback(() => {
    setIsConfirmationOpen(true);
  }, []);

  const handleConfirmationClose = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const handleCloseSupportDetail = useCallback(() => {
    setOpenSupportDetail(false);
    setOpenSupportList(true);
    setTicket({});
    setTicketMessages([]);
  }, [setOpenSupportDetail, setOpenSupportList, setTicket, setTicketMessages]);

  const handleConfirmationYes = useCallback(() => {
    // Make a POST request to close the support ticket
    axios
      .post(`ticket/close/${ticket.id}`)
      .then((response) => {
        toast.success("Talep kapatıldı.")
        setTimeout(() => {
          window.location.reload();
        }, 1100);
      })
      .catch((error) => {
        console.error(error);
      });

    // Close the confirmation dialog
    setIsConfirmationOpen(false);
  }, [ticket.id]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="support-detail">
        <Box className="support-detail-container">
          <Dialog open={isConfirmationOpen} onClose={handleConfirmationClose}>
            <DialogContent>
              <Box>Destek talebini kapatmak istiyor musunuz?</Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmationYes}>Evet</Button>
              <Button onClick={handleConfirmationClose}>Hayır</Button>
            </DialogActions>
          </Dialog>
          <Box className="support-detail-info">
            <Box className="detail-info-text">
              <Box
                sx={{
                  cursor: "pointer",
                  color: "var(--shipmentBlue)",
                  fontSize: "15px",
                }}
                onClick={handleCloseSupportDetail}
              >
                Geri Dön
              </Box>
            </Box>

            <Box className="detail-info-text">Vaka Numarası: {ticket?.ticket_code}</Box>
            <Box className="detail-info-text">
              Müşteri ID: {ticket?.member_id}
            </Box>
            <Box className="detail-info-text">
              Oluşturan: {ticket?.get_created_by.name}{" "}
              {ticket?.get_created_by.surname}
            </Box>
            <Box className="detail-info-text">Konu: {ticket?.subject_name}</Box>
            <Box className="detail-info-text">
              <Box
                sx={{
                  cursor: "pointer",
                  color: "var(--shipmentRed)",
                  fontSize: "15px",
                }}
                onClick={handleCloseSupportTicket}
              >
                Talebi Kapat
              </Box>
            </Box>
          </Box>
          {ticket?.get_parcel?.tracking_awb && (
            <Box
              sx={{
                backgroundColor: "var(--lighterBg)",
                padding: "20px",
                borderRadius: "var(--inputBorderRadius)",
                boxShadow: "var(--shadow)",
              }}
            >
              <span style={{ fontWeight: 700, color: "var(--info)" }}>
                Takip No:{" "}
              </span>

              <span
                onClick={() =>
                  getTrackingLinkByCargo(
                    trackingCargo,
                    ticket?.get_parcel?.tracking_awb
                  )
                }
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "var(--primary)",
                }}
              >
                {ticket?.get_parcel?.tracking_awb}
              </span>
            </Box>
          )}
          {ticket.get_parcel !== null && (
            <Box className="stepper">
              <Box className="stepper-wrapper">
                <SupportDetailStepperCountries />
                <SupportDetailStepper />
              </Box>
              <SupportDetailStepperInfo />
            </Box>
          )}
          <SupportDetailChatAdmin />
          <Box ref={cardRef}>
            <SupportDetailFilesAdmin />
          </Box>
          <SupportDetailIyziNoteAdmin />
          <SupportDetailNotes />
          <SupportDetailFinance rows={rows} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SupportDetailAdmin;
