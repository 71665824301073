import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import MessageTime from "../../../../utils/MessageTime";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import { useRecoilState } from "recoil";
import {
  memberDocumentEditOpenState,
  memberInformationEditOpenState,
  memberListState,
} from "../../../../atoms/members";
import { useNavigate } from "react-router-dom";
import { searchAdminBakiyeIdState } from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";
import { toast } from "react-toastify";
import getSuperAdminRoleCheck from "../../../../utils/getSuperAdminRoleCheck";

const MemberCompany = ({ member }) => {
  const navigate = useNavigate();
  const superAdminRole = getSuperAdminRoleCheck();

  const [, setMemberDocumentEditOpen] = useRecoilState(
    memberDocumentEditOpenState
  );
  const [, setMemberInformationEditOpen] = useRecoilState(
    memberInformationEditOpenState
  );
  const [, setSearchAdminBakiyeId] = useRecoilState(searchAdminBakiyeIdState);
  const [memberList, setMemberList] = useRecoilState(memberListState);

  const [memberActiveLoading, setMemberActiveLoading] = useState(false);
  const [memberCurrentLoading, setMemberCurrentLoading] = useState(false);
  const [memberCurrent, setMemberCurrent] = useState(
    member?.uyumsoft_code && member?.uyumsoft_advance_code ? true : false
  );
  const [memberStatus, setMemberStatus] = useState(member?.status);

  const [openCari, setOpenCari] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const [openStatus, setOpenStatus] = useState(null);

  const handleCariClose = () => {
    setOpenCari(false);
  };

  const handleChangeStatus = (e) => {
    if (Number(memberStatus) === e.target.value) return;
    setOpenStatus(e.target.value);
    setOpenStatusChange(true);
  };

  const handleChangeStatusClose = () => {
    setOpenStatusChange(false);
    setOpenStatus(member?.status);
  };

  const activeChange = useCallback(async () => {
    setMemberActiveLoading(true);
    setOpenStatusChange(false);

    if (superAdminRole) {
      await axios
        .post(`member/update-status/${member?.id}`, {
          status: openStatus,
        })
        .then((response) => {
          toast.success(response.data.message);
          setMemberStatus(response.data.data.status);

          if (
            member.uyumsoft_code !== response.data.data?.uyumsoft_code &&
            member.uyumsoft_advance_code !==
              response.data.data?.uyumsoft_advance_code
          ) {
            setMemberCurrent(true);
          }

          const updatedArray = memberList?.map((item) => {
            if (item.id === member?.id) {
              // gelen veriyi bu öğeye ata
              return {
                ...item,
                status: response.data.data?.status,
                status_name: response.data.data?.status_name,
                uyumsoft_advance_code:
                  response.data.data?.uyumsoft_advance_code,
                uyumsoft_code: response.data.data?.uyumsoft_code,
              };
            }
            // diğer öğeleri olduğu gibi döndür
            return item;
          });

          // güncellenmiş diziyi state'e kaydet
          setMemberList(updatedArray);
        })
        .catch((error) => Errors(error))
        .finally(() => setMemberActiveLoading(false));
    }
  }, [member, superAdminRole, openStatus, memberList, setMemberList]);

  const currentChange = useCallback(async () => {
    handleCariClose();
    setMemberCurrentLoading(true);
    if (superAdminRole) {
      await axios
        .post(`member/register-uyumsoft-profile/${member?.id}`)
        .then((response) => {
          toast.success(response.data.message);
          setMemberCurrent(true);

          const updatedArray = memberList?.map((item) => {
            if (item.id === member?.id) {
              // gelen veriyi bu öğeye ata
              return {
                ...item,
                status: response.data?.data?.status,
                status_name: response.data?.data?.status_name,
                uyumsoft_advance_code:
                  response.data.data?.uyumsoft_advance_code,
                uyumsoft_code: response.data.data?.uyumsoft_code,
              };
            }
            // diğer öğeleri olduğu gibi döndür
            return item;
          });

          // güncellenmiş diziyi state'e kaydet
          setMemberList(updatedArray);
        })
        .catch((error) => Errors(error))
        .finally(() => setMemberCurrentLoading(false));
    }
  }, [member, superAdminRole, memberList, setMemberList]);

  const handleMemberDocument = useCallback(() => {
    setMemberDocumentEditOpen({ status: true, data: member });
  }, [member, setMemberDocumentEditOpen]);

  const handleMemberInformation = useCallback(() => {
    setMemberInformationEditOpen({ status: true, data: member });
  }, [member, setMemberInformationEditOpen]);

  const handleNavigateWithMemberID = useCallback(
    (page) => {
      setSearchAdminBakiyeId(member.id);
      navigate(`/${page}?memberId=${member.id}`);
    },
    [navigate, member, setSearchAdminBakiyeId]
  );

  useEffect(() => {
    setMemberStatus(member?.status);
    setMemberCurrent( member?.uyumsoft_code && member?.uyumsoft_advance_code ? true : false)
  }, [member?.status, member?.uyumsoft_code, member?.uyumsoft_advance_code])

  return (
    <Box
      className="member-list-row"
      sx={{
        backgroundColor:
          member?.member_type === "Company"
            ? "var(--pastelRed)"
            : "var(--pastelBlue)",
      }}
    >
      <Dialog
        open={openCari}
        onClose={handleCariClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cari açıyorsunuz. Onaylıyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button-blue" onClick={handleCariClose}>
            Hayır
          </Button>
          <Button
            className="iyzi-button-blue"
            onClick={currentChange}
            autoFocus
          >
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStatusChange}
        onClose={handleChangeStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Üye durumunu değiştiriyorsunuz. Onaylıyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="iyzi-button-blue"
            onClick={handleChangeStatusClose}
          >
            Hayır
          </Button>
          <Button className="iyzi-button-blue" onClick={activeChange} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Avatar
        src={`data:image/png;base64,${member?.get_member_users[0]?.photo}`}
        sx={{ margin: "auto", width: "80px", height: "80px" }}
      />
      <Box className="member-list-column">
        <Typography fontSize={13} color={"var(--priceText)"}>
          ID: #{member?.id}
        </Typography>
        <Typography fontSize={13} color={"var(--priceText)"}>
          {member?.name}
        </Typography>
        <Typography fontSize={13} color={"var(--priceText)"}>
          TC: {member?.identity_no === null ? "---" : member?.identity_no}
        </Typography>
      </Box>
      <Box className="member-list-column buttons">
        <Button
          onClick={() => handleNavigateWithMemberID("finance")}
          className="iyzi-button-blue"
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: "160px",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "12px",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <WalletOutlinedIcon />
              Cüzdanını Göster
            </Box>
          </Box>
        </Button>
        <Button onClick={handleMemberDocument} className="iyzi-button-blue">
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: "160px",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "12px",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <AppRegistrationOutlinedIcon />
              Üyelik Detayları
            </Box>
          </Box>
        </Button>
        <Button
          onClick={() => handleNavigateWithMemberID("my-shipment")}
          className="iyzi-button-blue"
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: "160px",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "12px",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <LocalShippingOutlinedIcon /> Yüklerini Göster
            </Box>
          </Box>
        </Button>
        {/* <Button onClick={handleMemberInformation} className="iyzi-button-blue">
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "160px",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "12px",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <CreateOutlinedIcon /> Kullanıcı Güncelle
            </Box>
          </Box>
        </Button> */}
      </Box>
      <Box className="member-list-column">
        <Box className="member-list-column-container">
          <Box>
            <b>Üyelik Tarihi:</b>{" "}
            <span style={{ color: "var(--info)", fontSize: "12px" }}>
              {MessageTime(member?.get_member_users[0]?.created_at)}
            </span>
          </Box>
          {member?.get_member_latest_parcel?.created_at && (
              <Box className="member-list-column-text">
                <b>Son Gönderi Oluşturma Tarihi:</b><br/>
                <span style={{color: "var(--info)", fontSize: "12px"}}>
                  {MessageTime(member?.get_member_latest_parcel?.created_at)}
                </span>{" "}{" "}<b>Gün:</b>{" "}{member?.day}
              </Box>
          )}
          {member?.get_member_users[0]?.phone && (
            <Box className="member-list-column-text">
              {member?.get_member_users[0]?.phone}
            </Box>
          )}
          {member?.get_member_users[0]?.email && (
            <Box className="member-list-column-text">
              {member?.get_member_users[0]?.email}
            </Box>
          )}
          {member?.get_member_invoice_address && (
            <Box className="member-list-column-text">
              <Box>
                {member?.get_member_invoice_address?.address_description &&
                  `${member?.get_member_invoice_address?.address_description} -`}{" "}
                {member?.get_member_invoice_address?.contact_name &&
                  `${member?.get_member_invoice_address?.contact_name}`}
              </Box>
              <Box>
                {member?.get_member_invoice_address?.address1 &&
                  member?.get_member_invoice_address?.address1}{" "}
                {member?.get_member_invoice_address?.address2 &&
                  member?.get_member_invoice_address?.address2}{" "}
                {member?.get_member_invoice_address?.address3 &&
                  member?.get_member_invoice_address?.address3}{" "}
              </Box>
              <Box>
                {member?.get_member_invoice_address?.state &&
                  `${member?.get_member_invoice_address?.state}`}
                {member?.get_member_invoice_address?.town &&
                  `, ${member?.get_member_invoice_address?.town}`}
                {member?.get_member_invoice_address?.city &&
                  `, ${member?.get_member_invoice_address?.city}`}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="member-list-column buttons">
        <FormControl disabled={!superAdminRole} fullWidth>
          <InputLabel id="approve-status-select-label">Onay Durumu</InputLabel>
          <Select
            labelId="approve-status-select-label"
            id="approve-status-select"
            value={memberStatus}
            label="Onay Durumu"
            size="small"
            onChange={handleChangeStatus}
          >
            <MenuItem value={0}>Pasif</MenuItem>
            <MenuItem value={1}>Onay Sürecinde</MenuItem>
            <MenuItem value={2}>Onaylandı</MenuItem>
          </Select>
        </FormControl>
        {memberActiveLoading && (
          <CircularProgress
            sx={{
              width: "24px!important",
              height: "24px!important",
              margin: "0 auto",
            }}
          />
        )}
        {!memberActiveLoading && (
          <Box
            className={`list-column ${
              Number(member?.status) === 0
                ? "passive"
                : Number(member?.status) === 1
                ? "hold"
                : Number(member?.status) === 2
                ? "active"
                : ""
            }`}
          >
            {Number(member?.status) === 0 && <CancelOutlinedIcon />}{" "}
            {Number(member?.status) === 1 && <RotateRightOutlinedIcon />}{" "}
            {Number(member?.status) === 2 && <CheckCircleOutlineOutlinedIcon />}{" "}
            {member?.status_name}
          </Box>
        )}
        {memberCurrentLoading && (
          <CircularProgress
            sx={{
              width: "24px!important",
              height: "24px!important",
              margin: "0 auto",
            }}
          />
        )}
        {!memberCurrentLoading && memberCurrent && (
          <div className="member-list-column-active-button">
            <CheckCircleOutlineOutlinedIcon />
            Açık Cari
          </div>
        )}
        {!memberCurrentLoading && !memberCurrent && (
          <button
            type="button"
            className="currentToActive"
            disabled={!superAdminRole}
            onClick={() => setOpenCari(true)}
            style={{
              opacity: superAdminRole ? 1 : 0.3,
              cursor: superAdminRole ? "pointer" : "auto",
            }}
          >
            <span className="text">
              <CancelOutlinedIcon />
              Kapalı Cari
            </span>
            <span>Açık Cari'ye Çevir</span>
          </button>
        )}
      </Box>
    </Box>
  );
};

export default MemberCompany;
