import "../../style/courierRegistration/CourierRegistrationAdmin.css";
import { useRecoilState } from "recoil";
import { useInView, animated } from "@react-spring/web";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
import NavBar from "../components/sidebar/NavBar";
import NotificationBar from "../components/sidebar/NotificationBar";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import { useCallback, useEffect, useState } from "react";
import { DynamicTitle } from "../../utils/DynamicTitle";
import {
  courierCurrentPageAdminState,
  courierListAdminState,
  courierTotalPagesAdminState,
} from "../../atoms/courierRegistration";
import CourierListAdmin from "../components/courierRegistration/CourierListAdmin";
import CourierAbortAdmin from "../components/courierRegistration/CourierAbortAdmin";
import WrapperStyle from "../../utils/WrapperStyle";
import Errors from "../../utils/Errors";

const CourierRegistrationAdmin = () => {
  DynamicTitle("Kurye Kaydı - Admin");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const [, setCourierListAdmin] = useRecoilState(courierListAdminState);
  const [, setCourierCurrentPageAdmin] = useRecoilState(courierCurrentPageAdminState);
  const [, setCourierTotalPagesAdmin] = useRecoilState(courierTotalPagesAdminState);

  const [loading, setLoading] = useState(false);

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 20,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
    }
  );

  const getData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`booking?order_direction=DESC`)
      .then((response) => {
        setCourierListAdmin(response.data.data);
        setCourierCurrentPageAdmin(response.data.currentPage);
        setCourierTotalPagesAdmin(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [setCourierListAdmin, setCourierCurrentPageAdmin, setCourierTotalPagesAdmin]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section className="courier-registration">
      <NavBar />
      <NotificationBar />
      <ToastContainer />
      <CourierAbortAdmin />
      <Box className="courier-registration-wrapper" style={WrapperStyle()}>
        {loading ? (
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-logo" src={miniLogo} alt="" />
          </Box>
        ) : (
          <animated.div ref={ref} style={springs}>
            <Box className="courier-registration-container">
              <CourierListAdmin />
            </Box>
          </animated.div>
        )}
      </Box>
    </section>
  );
};

export default CourierRegistrationAdmin;
