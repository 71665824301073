import { Box, Button, createTheme, InputAdornment, TextField, ThemeProvider, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, FormGroup } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import {
  financeFaturaInvoicingCurrentPageState,
  financeFaturaInvoicingDataState,
  financeFaturaInvoicingTotalPageState,
  invoicingModalState,
} from "../../../../atoms/finance";
import axios from "../../../../api/axios";
import Errors from "../../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
  },
});

const FaturaInvoicingOptions = () => {
  const [, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  

  const [, setFinanceFaturaInvoicingData] = useRecoilState(financeFaturaInvoicingDataState);
  const [, setFinanceFaturaInvoicingTotalPage] = useRecoilState(financeFaturaInvoicingTotalPageState);
  const [, setFinanceFaturaInvoicingCurrentPage] = useRecoilState(financeFaturaInvoicingCurrentPageState);
  
  const [, setInvoicingModal] = useRecoilState(invoicingModalState);

  const handleInvoicingModal = () => {
    setInvoicingModal(true);
  }

  return (
    <ThemeProvider theme={theme}>
      
    </ThemeProvider>
  );
};

export default FaturaInvoicingOptions;
