import { TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import CurrencySymbol from "../../../../utils/CurrencySymbol";

const FaturaKonsimentoTableBody = ({ item }) => {
  return (
    <TableRow
      sx={{
        transition: "all 150ms linear",
        ":hover": {
          transition: "all 150ms linear",
          backgroundColor: "var(--pastelBlue)",
        },
      }}
    >
      <TableCell>{item?.name}</TableCell>
      <TableCell>
        {
          <Link
            style={{ fontSize: "inherit", color: "var(--inherit)" }}
            to={`/my-shipment?awb=${item?.parcel_ref_no}`}
          >
            {item?.parcel_ref_no}
          </Link>
        }
      </TableCell>
      <TableCell>
        {item?.parcel_return_no === "0" ? (
          "---"
        ) : (
          <Link
            style={{ fontSize: "inherit", color: "var(--inherit)" }}
            to={`/my-shipment?awb=${item?.parcel_return_no}`}
          >
            {item?.parcel_return_no}
          </Link>
        )}
      </TableCell>
      <TableCell>{item?.receiver_name}</TableCell>
      <TableCell>{item?.receiver_country}</TableCell>
      <TableCell>{item?.qty}</TableCell>
      <TableCell>%{item?.vat}</TableCell>
      <TableCell>
        {item?.item_amount} {CurrencySymbol(item?.currency)}
      </TableCell>
    </TableRow>
  );
};

export default FaturaKonsimentoTableBody;
