import { useRecoilValue } from "recoil";
import { logDataState } from "../../../atoms/log";
import { Table, TableBody, TableContainer } from "@mui/material";
import LogTableBody from "./LogTableBody";
import LogTableHead from "./LogTableHead";

const LogTable = () => {
  const logData = useRecoilValue(logDataState);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        boxShadow: "var(--shadow)",
        borderRadius: "var(--wrapperBorderRadius)",
      }}
    >
      <Table>
        <LogTableHead />
        <TableBody>
          {logData.map((item, key) => (
            <LogTableBody key={key} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogTable;
