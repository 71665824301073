import { Box, Pagination } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import "../../../style/members/Members.css";
import {
  memberListState,
  memberTotalPageState,
  memberListCurrentPageState,
  memberListLoadingState,
} from "../../../atoms/members";
import MemberCompany from "./memberListItems/MemberCompany";
import MemberSearch from "./MemberSearch";
import MemberDocumentEdit from "./memberListItems/MemberDocumentEdit";
import MemberInformationEdit from "./memberListItems/MemberInformationEdit";
import axios from "../../../api/axios";
import Errors from "../../../utils/Errors";

const MemberList = () => {
  const [memberList, setMemberList] = useRecoilState(memberListState);
  const [memberListCurrentPage, setMemberListCurrentPage] = useRecoilState(
    memberListCurrentPageState
  );
  const [memberTotalPage, setMemberTotalPage] = useRecoilState(memberTotalPageState);

  const setMemberListLoading = useSetRecoilState(memberListLoadingState);

  const handlePageChange = (e, pageValue) => {
    const params = {
      order_direction: "DESC",
      limit: 50,
      page: pageValue,
    };

    axios
      .get(`member`, { params: params })
      .then((response) => {
        setMemberList(response.data.data);
        setMemberListCurrentPage(pageValue);
        setMemberTotalPage(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setMemberListLoading(false));
  };

  return (
    <Box className="member-list">
      <MemberDocumentEdit />
      <MemberInformationEdit />
      <MemberSearch />

      {memberList.map((member, key) => (
        <MemberCompany key={key} member={member} />
      ))}
      <Box
        sx={{
          marginBottom: "20px",
          backgroundColor: "var(--lighterBg)",
          borderRadius: "var(--inputBorderRadius)",
          width: "100%",
          padding: "20px",
          boxShadow: "0px 2px 10px rgb(0 0 0 / 10%)",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          showFirstButton
          showLastButton
          size="small"
          count={memberTotalPage}
          page={memberListCurrentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default MemberList;
