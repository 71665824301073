import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../../api/axios";
import {
  appointeeListState,
  caseListState,
  openSupportDetailState, parcelDocumentState,
  teamNoteListState,
  ticketDimensionsState,
  ticketDocumentState,
  ticketMessagesState,
  ticketState,
} from "../../../../atoms/support";
import Errors from "../../../../utils/Errors";
import FormatDateHour from "../../../../utils/FormatDateHour";
import { lastTicketState } from "../../../../atoms/myShipments";
import NavigateParcelAdmin from "../../../../utils/NavigateParcelAdmin";
import CopyToClipboard from "react-copy-to-clipboard";

const SupportListTableBodyAdmin = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [appointee, setAppointee] = useState(item.assigned_user_id ? item.assigned_user_id : 0);
  const [, setTicket] = useRecoilState(ticketState);
  const [, setOpenSupportDetail] = useRecoilState(openSupportDetailState);
  const [, setTicketDocument] = useRecoilState(ticketDocumentState);
  const [, setParcelDocument] = useRecoilState(parcelDocumentState);
  const [, setCaseList] = useRecoilState(caseListState);
  const [, setTeamNoteList] = useRecoilState(teamNoteListState);
  const [, setTicketMessages] = useRecoilState(ticketMessagesState);
  const [, setTicketDimensions] = useRecoilState(ticketDimensionsState);
  const [, setLastTicket] = useRecoilState(lastTicketState);

  const [selectedColor, setSelectedColor] = useState(Number(item?.rating));
  const [textInput, setTextInput] = useState(item?.rating_message);

  const appointeeList = useRecoilValue(appointeeListState);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const role = userInfo?.user?.user_role?.name;

  const handleOpenSupportDetail = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`ticket/${item.id}`)
      .then((response) => {
        setLoading(false);
        setOpenSupportDetail(true);
        setTicket(response.data.data);
        setTicketDocument(response.data.data.get_ticket_documents);
        setParcelDocument(response.data.data?.get_parcel?.get_parcel_documents);
        setCaseList(response.data.data.get_case);
        setTeamNoteList(response.data.data.get_ticket_notes);
        setTicketMessages(response.data.data.get_ticket_messages);
        setTicketDimensions(
          response.data.data?.get_parcel?.get_parcel_dimensions
        );
        setLastTicket(item.id);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    setOpenSupportDetail,
    setTicket,
    item.id,
    setTicketDocument,
    setParcelDocument,
    setCaseList,
    setTeamNoteList,
    setTicketMessages,
    setTicketDimensions,
  ]);

  const sendRatingData = useCallback(async () => {
    const endpoint = `ticket/update/${item.id}`;
    const data = {
      rating: selectedColor,
      rating_message: textInput,
    };
    return axios
      .post(endpoint, data)
      .then((response) => {
        setSelectedColor(Number(response.data.data.rating));
        setTextInput(response.data.data.rating_message);
      })
      .catch((error) => Errors(error));
  }, [selectedColor, item.id, textInput]);


  const handleChangeAppointee = useCallback(
    async (e) => {
      setAppointee(e.target.value);

      await axios
        .post(`ticket/update-assigned-user/${item.id}`, {
          user_id: e.target.value,
        })
        .then((response) => {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
        })
        .catch((error) => Errors(error));
    },
    [item.id]
  );

  return (
    <TableRow>
      <TableCell>{item?.ticket_code}</TableCell>
      <TableCell>
        <Box sx={{
          display: "flex",
          gap: "10px"

        }}>
          <NavigateParcelAdmin parcel={item?.parcel_ref_no} />
          {item?.parcel_ref_no !== null &&
            <CopyToClipboard text={item?.parcel_ref_no}>
              <Button sx={{
                width: "10px",
              }} variant="contained" color="primary">
                <ContentCopyIcon fontSize={"10px"} />
              </Button>
            </CopyToClipboard>
          }
        </Box>
      </TableCell>
      <TableCell>{item?.subject_name}</TableCell>
      <TableCell>
        <Box
          sx={
            Number(item?.status) === 0
              ? {
                backgroundColor: "var(--pastelRed)",
                borderRadius: "var(--wrapperBorderRadius)",
                color: "var(--redBrand)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3px 20px",
                fontSize: "13px",
                width: "200px",
                textAlign: "center",
              }
              : Number(item?.status) === 1
                ? {
                  backgroundColor: "var(--pastelYellow)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  color: "var(--orangeBrand)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "3px 20px",
                  fontSize: "13px",
                  width: "200px",
                  textAlign: "center",
                }
                : Number(item?.status) === 2
                  ? {
                    backgroundColor: "var(--pastelRed)",
                    borderRadius: "var(--wrapperBorderRadius)",
                    color: "var(--redBrand)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "3px 20px",
                    fontSize: "13px",
                    width: "200px",
                    textAlign: "center",
                  }
                  : Number(item?.status) === 3
                    ? {
                      backgroundColor: "var(--pastelBlue)",
                      borderRadius: "var(--wrapperBorderRadius)",
                      color: "var(--blueBrand)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "3px 20px",
                      fontSize: "13px",
                      width: "200px",
                      textAlign: "center",
                    }
                    : {}
          }
        >
          {Number(item?.status) === 0
            ? "Yeni Talep"
            : Number(item?.status) === 1
              ? "Üyeden Cevap Bekleniyor"
              : Number(item?.status) === 2
                ? "iyziTeam'den Cevap Bekleniyor"
                : Number(item?.status) === 3
                  ? "Kapalı"
                  : ""}
        </Box>
      </TableCell>
      <TableCell>
        {item?.get_created_by?.name} {item?.get_created_by?.surname}
      </TableCell>
      <TableCell>
        <Select
          sx={{
            width: "70px",
            color:
              Number(selectedColor) === 1 ? "red"
                : Number(selectedColor) === 2 ? "green"
                  : Number(selectedColor) === 3 ? "orange"
                    : Number(selectedColor) === 4 ? "blue"
                      : "black",
          }}
          value={selectedColor}
          onChange={(event) => { setSelectedColor(event.target.value); }}
          onBlur={sendRatingData}
        >
          <MenuItem sx={{ color: "red" }} value={1}><FlagIcon /></MenuItem>
          <MenuItem sx={{ color: "green" }} value={2}><FlagIcon /></MenuItem>
          <MenuItem sx={{ color: "orange" }} value={3}><FlagIcon /></MenuItem>
          <MenuItem sx={{ color: "blue" }} value={4}><FlagIcon /></MenuItem>

        </Select>
      </TableCell>
      <TableCell>
        <TextField
          sx={{
            width: "170px",
          }}
          label="Not"
          size="large"
          value={textInput}
          onChange={(event) => { setTextInput(event.target.value); }}
          onBlur={sendRatingData}
          fullWidth
        />
      </TableCell>
      <TableCell>
        {role === "BOS" ||
          role === "ADM" ||
          role === "DEV" ||
          role === "SPC" ? (
          <Select
            size="small"
            fullWidth
            id="appointee-select"
            value={appointee}
            onChange={handleChangeAppointee}
            label=""
            sx={{
              fontSize: "13px",
              backgroundColor: "var(--info)",
              color: "var(--lighterBg)",
              outline: 0,
              border: 0,
            }}
          >
            <MenuItem key={0} disabled value={0}>
              Boş
            </MenuItem>
            {appointeeList.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.name} {e.surname}
              </MenuItem>
            ))}
          </Select>
        ) : item.get_assigned_user !== null ? (
          `${item.get_assigned_user.name} ${item.get_assigned_user.surname}`
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell>
        {item?.created_at ? FormatDateHour(item?.created_at) : "---"}
      </TableCell>
      <TableCell>
        {item?.updated_at ? FormatDateHour(item?.updated_at) : "---"}
      </TableCell>
      <TableCell>
        {item.closed_date === null && "---"}
        {item.closed_date?.slice(0, 9).split("-").reverse().join(".")}
        <br />
        {item.closed_date?.slice(11, 19)}
      </TableCell>
      <TableCell>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cemter",
            }}
          >
            <CircularProgress
              sx={{ width: "24px!important", height: "24px!important" }}
            />
          </Box>
        ) : (
          <Button
            onClick={handleOpenSupportDetail}
            sx={{
              textTransform: "none",
              backgroundColor: "var(--info)",
              color: "var(--lighterBg)",
              transition: "all 150ms linear",
              ":hover": {
                backgroundColor: "var(--blueBrand)",
                transition: "all 150ms linear",
              },
            }}
          >
            Göster
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SupportListTableBodyAdmin;
