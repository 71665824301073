import "../../../style/sidebar/Navbar.css";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import DataArrayIcon from "@mui/icons-material/DataArray";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { drawerOpenState } from "../../../atoms/viewPadding";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createTheme,
  Divider,
  Tooltip,
} from "@mui/material";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import GetRole from "../../../utils/GetRole";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

const theme = createTheme({
  components: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          margin: "0 !important",
          ":before": { height: 0 },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "48px!important",
        },
        content: {
          margin: "0 !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "14px!important",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "24px !important",
        },
      },
    },
  },
});

const NavLinks = () => {
  const navigate = useNavigate();
  const drawerOpen = useRecoilValue(drawerOpenState);
  const [token] = useCookies(["jwt"]);
  const jwt = token?.jwt;

  const logoutHandler = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

    await axios
      .post("logout")
      .then((res) => {
        toast.success(res.data.message);
        localStorage.removeItem("user");
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        navigate("/");
      })
      .catch((err) => {
        toast.success(err.message);
        localStorage.removeItem("user");
        document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        navigate("/");
      });
  };

  const role = GetRole();

  return (
    <Box className="navbar-links">
      <List>
        {[
          { page: "Pano", location: "/dashboard", role: ["Admin"] },
          { page: "Gönderiler", location: "/my-shipment", role: ["Admin"] },
          { page: "İadeler", location: "/returns", role: ["Admin"] },
          {
            page: "Kurye Kaydı",
            location: "/currier-record",
            role: ["Admin"],
          },
          { page: "Üyeler", location: "/members", role: ["Admin"] },
          // { page: "Raporlar", location: "/reports", role: ["Admin"] },
          (role === "BOS" || role === "DEV") && [
            {
              page: "Göstergeler",
              location: "/reports/charts",
              role: ["DEV", "BOS"],
            },
            {
              page: "Karlılık",
              location: "/reports/profit",
              role: ["DEV", "BOS"],
            },
            {
              page: "Yeni Üye",
              location: "/reports/new-member",
              role: ["DEV", "BOS"],
            },
            {
              page: "Kayıp Üye",
              location: "/reports/lost-member",
              role: ["DEV", "BOS"],
            },
            {
              page: "Destek",
              location: "/reports/support",
              role: ["DEV", "BOS"],
            },
            {
              page: "Bütçe",
              location: "/reports/budget",
              role: ["DEV", "BOS"],
            },
            {
              page: "İadeler",
              location: "/reports/return",
              role: ["DEV", "BOS"],
            },
            {
              page: "Satış Raporu",
              location: "/operation-progress/sales-report",
              role: ["DEV", "BOS"],
            },
          ],
          [
            /*/{
              page: "Yük Giriş Ön Büro",
              location: "/operation-progress/freight-front-desk",
              role: ["Admin"],
            },/*/
            {
              page: "Gönderi İade İşlemleri",
              location: "/operation-progress/shipment-refund-request",
              role: ["Admin"],
            },
            {
              page: "Mal Kabul",
              location: "/operation-progress/cargo-entrance-warehouse",
              role: ["Admin"],
            },
            {
              page: "Gön. İzle & Statü Atlatma",
              location: "/operation-progress/get-set-status",
              role: ["Admin"],
            },
            {
              page: "Gönderi Etiket Yazdırma",
              location: "/operation-progress/get-barcode",
              role: ["Admin"],
            },
            /*/{
              page: "Güvenlik Kontrolü",
              location: "/operation-progress/security-check",
              role: ["Admin"],
            },
            {
              page: "Ölçü Kontrolü",
              location: "/operation-progress/measure-control",
              role: ["Admin"],
            },
            {
              page: "Ödeme Kontrolü",
              location: "/operation-progress/payment-control",
              role: ["Admin"],
            },
            {
              page: "Geçici Beklemeye Al",
              location: "/operation-progress/temporary-hold",
              role: ["Admin"],
            },
            {
              page: "Yük Çıkış",
              location: "/operation-progress/load-output",
              role: ["Admin"],
            },/*/
            {
              page: "BeeVision Ayarları",
              location: "/operation-progress/beevision-settings",
              role: ["Admin"],
            },
            // {
            //   page: "BeeVision Konsol Ayarları",
            //   location: "/operation-progress/beevision-console-settings",
            //   role: ["Admin"],
            // },
            // {
            //   page: "Otomatik KG Farkı Gönder",
            //   location: "/operation-progress/auto-send-difference-kg",
            //   role: ["Admin"],
            // },
            {
              page: "BeeVision Geçen Yük Ekranı",
              location: "/operation-progress/beevision-passing-load-screen",
              role: ["Admin"],
            },
            {
              page: "Fiyat Kontrol Ekranı",
              location: "/operation-progress/price-control-screen",
              role: ["Admin"],
            },
            {
              page: "USPS",
              location: "/operation-progress/usps",
              role: ["Admin"],
            },
            {
              page: "Takip Numarası Aktarma",
              location: "/operation-progress/awb-transfer",
              role: ["Admin"],
            },
          ],
          {
            page: "Finans",
            location: "/finance",
            role: ["Admin"],
          },
          {
            page: "Adres Defteri",
            location: "/address-book",
            role: ["Admin"],
          },
          {
            page: "Destek Talebi",
            location: "/support",
            role: ["Admin"],
          },
          {
            page: "Duyurular",
            location: "/announcement",
            role: ["Superadmin"],
          },
          (role === "BOS" || role === "DEV") && [
            {
              page: "Sistem Ayarları",
              location: "/system/system-settings",
              role: ["DEV", "BOS"],
            },
            {
              page: "Kur",
              location: "/system/exchange-rates",
              role: ["DEV", "BOS"],
            },
            {
              page: "Fiyat Sorgulama",
              location: "/system/price-check",
              role: ["DEV", "BOS"],
            },
            {
              page: "Log",
              location: "/system/log",
              role: ["DEV", "BOS"],
            },
          ],
        ].map((text, index) =>
          Array.isArray(text) ? (
            <ThemeProvider key={index} theme={theme}>
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreOutlined sx={{ display: drawerOpen ? "block" : "none" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ position: "relative" }}
                >
                  {index === 1 && (
                    <>
                      <ShoppingCartOutlinedIcon
                        sx={{
                          marginLeft: drawerOpen ? "8px" : "3px",
                          color: "var(--priceText)",
                          transition: "all 150ms linear",
                        }}
                      />
                      <ExpandMoreOutlined
                        sx={{
                          display: drawerOpen ? "none" : "block",
                          color: "var(--priceText)",
                        }}
                      />
                    </>
                  )}
                  <Tooltip
                    title={
                      drawerOpen
                        ? ""
                        : index === 5
                        ? "Raporlar"
                        : index === 6
                        ? "Operasyon Süreçleri"
                        : index === 11
                        ? "Sistem Yönetimi"
                        : ""
                    }
                    placement="right"
                  >
                    <ListItemIcon
                      sx={
                        index === 5 || index === 6 || index === 11
                          ? {
                              marginRight: "-3px",
                              marginLeft: drawerOpen ? "7px" : "3px",
                              justifyContent: "center",
                              transition: "all 150ms linear",
                              display: "flex",
                              alignItems: "center",
                            }
                          : window.location.pathname === text.location
                          ? {
                              minWidth: 0,
                              mr: drawerOpen ? 3 : 3,
                              ml: drawerOpen ? 2.4 : 1.9,
                              justifyContent: "center",
                              transition: "all 150ms linear",
                            }
                          : {
                              minWidth: 0,
                              mr: drawerOpen ? 3 : 3,
                              ml: drawerOpen ? 3 : 2.5,
                              justifyContent: "center",
                              transition: "all 150ms linear",
                            }
                      }
                      style={
                        window.location.pathname === text.location
                          ? { color: "var(--navActiveLink)" }
                          : {}
                      }
                    >
                      {index === 5 ? (
                        <AssessmentOutlinedIcon />
                      ) : index === 6 ? (
                        <ExtensionOutlinedIcon />
                      ) : index === 11 ? (
                        <DataArrayIcon />
                      ) : null}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      index === 5
                        ? "Raporlar"
                        : index === 6
                        ? "Operasyon Süreçleri"
                        : index === 11
                        ? "Sistem Yönetimi"
                        : ""
                    }
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      marginLeft: "24px",
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {text.map((subText, subIndex) => (
                      <ListItem key={subIndex} className="main-link-wrapper" disablePadding>
                        <NavLink
                          style={{
                            minHeight: 30,
                            justifyContent: drawerOpen ? "initial" : "center",
                            px: 2.5,
                            display: "flex",
                            textDecoration: "none",
                            color: "currentcolor",
                            alignItems: "center ",
                          }}
                          to={subText.location}
                          id={`navlink_menu_${subIndex}`}
                          className={"list-item-link"}
                        >
                          <Tooltip title={drawerOpen ? "" : subText.page} placement="right">
                            <ListItemIcon
                              sx={
                                window.location.pathname === subText.location
                                  ? {
                                      minWidth: 0,
                                      mr: drawerOpen ? 3 : 3,
                                      ml: drawerOpen ? 2.4 : 1.9,
                                      justifyContent: "center",
                                      transition: "all 150ms linear",
                                    }
                                  : {
                                      minWidth: 0,
                                      mr: drawerOpen ? 3 : 3,
                                      ml: drawerOpen ? 3 : 2.5,
                                      justifyContent: "center",
                                      transition: "all 150ms linear",
                                    }
                              }
                              style={
                                window.location.pathname === subText.location
                                  ? { color: "var(--navActiveLink)" }
                                  : {}
                              }
                            ></ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            style={
                              window.location.pathname === subText.location
                                ? { color: "var(--navActiveLink)" }
                                : {}
                            }
                            className="main-link"
                            primary={subText.page}
                            sx={{
                              opacity: drawerOpen ? 1 : 0,
                            }}
                          />
                        </NavLink>
                        <Divider />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          ) : (
            <ListItem
              key={index}
              className={window.location.pathname === text.location ? "active" : ""}
              disablePadding
              sx={{
                display: "block",
                backgroundColor: index === 9 ? "#fca20336" : "",
              }}
            >
              <NavLink
                style={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? "initial" : "center",
                  px: 2.5,
                  display: "flex",
                  textDecoration: "none",
                  color: "currentcolor",
                  alignItems: "center ",
                }}
                to={text.location}
                id={`navlink_menu_${index}`}
                className={"list-item-link"}
                reloadDocument
              >
                <Tooltip title={drawerOpen ? "" : text.page} placement="right">
                  <ListItemIcon
                    sx={
                      window.location.pathname === text.location
                        ? {
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 3,
                            ml: drawerOpen ? 2.4 : 1.9,
                            justifyContent: "center",
                            transition: "all 150ms linear",
                          }
                        : {
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 3,
                            ml: drawerOpen ? 3 : 2.5,
                            justifyContent: "center",
                            transition: "all 150ms linear",
                          }
                    }
                    style={
                      window.location.pathname === text.location
                        ? { color: "var(--navActiveLink)" }
                        : {}
                    }
                  >
                    {index === 0 ? (
                      <GridViewOutlinedIcon />
                    ) : index === 1 ? (
                      <ShoppingCartOutlinedIcon />
                    ) : index === 2 ? (
                      <Inventory2OutlinedIcon />
                    ) : index === 3 ? (
                      <ReplayOutlinedIcon />
                    ) : index === 4 ? (
                      <ExtensionOutlinedIcon />
                    ) : index === 5 ? (
                      <AssessmentOutlinedIcon />
                    ) : index === 6 ? (
                      <LocalShippingOutlinedIcon />
                    ) : index === 7 ? (
                      <AccountBalanceOutlinedIcon />
                    ) : index === 8 ? (
                      <MenuBookOutlinedIcon />
                    ) : index === 9 ? (
                      <SupportAgentIcon />
                    ) : index === 10 ? (
                      <NotificationsActiveOutlinedIcon />
                    ) : (
                      ""
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={
                    window.location.pathname === text.location
                      ? { color: "var(--navActiveLink)" }
                      : {}
                  }
                  className="list-item-text"
                  primary={text.page}
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
              </NavLink>
            </ListItem>
          )
        )}
        {["Güvenli Çıkış"].map((text, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: drawerOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={logoutHandler}
            >
              <Tooltip title={drawerOpen ? "" : text} placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 0,
                    justifyContent: "center",
                    marginLeft: drawerOpen ? "5px" : "2px",
                    marginRight: "20px",
                  }}
                >
                  <LogoutRoundedIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                className="navbar-link-text"
                primary={text}
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NavLinks;
