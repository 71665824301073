import { atom } from "recoil";

export const memberDocumentEditOpenState = atom({
  key: "memberDocumentEditOpen",
  default: { status: false, data: {} },
});

export const memberInformationEditOpenState = atom({
  key: "memberInformationEditOpen",
  default: { status: false, data: {} },
});

export const memberListState = atom({
  key: "memberList",
  default: [],
});

export const memberTotalPageState = atom({
  key: "memberTotalPage",
  default: 1,
});

export const memberTypeState = atom({
  key: "memberType",
  default: "",
});

export const memberCountryState = atom({
  key: "memberCountry",
  default: "",
});

export const memberBasicSearchState = atom({
  key: "memberBasicSearch",
  default: "",
});

export const memberCountState = atom({
  key: "memberCount",
  default: "",
});

export const memberBasicSearchErrorState = atom({
  key: "memberBasicSearchErrorState",
  default: false,
});

export const memberNameState = atom({
  key: "memberName",
  default: "",
});
export const memberCompanyTypeState = atom({
  key: "memberCompanyType",
  default: "",
});

export const memberFromDateState = atom({
  key: "memberFromDate",
  default: "",
});
export const memberToDateState = atom({
  key: "memberToDate",
  default: "",
});
export const memberIdentityNoState = atom({
  key: "memberIdentityNo",
  default: "",
});
export const memberTaxNoState = atom({
  key: "memberTaxNo",
  default: "",
});
export const memberCityState = atom({
  key: "memberCity",
  default: "",
});

export const memberTownState = atom({
  key: "memberTown",
  default: "",
});

export const memberPhoneState = atom({
  key: "memberPhone",
  default: "",
});

export const memberWithinTheDayState = atom({
  key: "memberWithinTheDay",
  default: "",
});

export const memberOutsideTheDayState = atom({
  key: "memberOutsideTheDay",
  default: "",
});

export const memberListLoadingState = atom({
  key: "memberListLoading",
  default: false,
});

export const memberDetailedStatusState = atom({
  key: "memberDetailedStatus",
  default: 0,
});

export const membersStatusState = atom({
  key: "membersStatus",
  default: "",
});

export const memberListCurrentPageState = atom({
  key: "memberCurrentListPage",
  default: 1,
});

export const memberChangeActivatedState = atom({
  key: "memberChangeActivated",
  default: false,
});

export const memberTimerModalState = atom({
  key: "memberTimerModal",
  default: false,
});

export const memberUserIDState = atom({
  key: "memberUserID",
  default: "",
});

export const memberIDState = atom({
  key: "memberID",
  default: "",
});
